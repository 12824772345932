import React, { useState } from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { CgWebsite } from "react-icons/cg";
import { FaAndroid } from "react-icons/fa";

import { BsBrowserChrome, BsGithub, BsTelegram } from "react-icons/bs";
import Modal from "react-bootstrap/Modal";
import styles from "./projectcard.module.css";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
function ImagesModal(props) {
  return (
    <Modal
      onHide={() => {
        props.setShow(false);
      }}
      {...props}
      show={props.show}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {props.title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div
          style={{
            padding: "20px",
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {props.screenshotsurls?.map((url, idx) => (
            <img
              key={idx}
              className={styles.screenshot}
              src={url}
              alt={props.title}
              style={{ margin: "10px" }}
            />
          ))}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => props.setShow(false)}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

const ProjectCard = (props) => {
  const [show, setShow] = useState(false);
  return (
    <Card className="project-card-view">
      <Card.Img variant="top" src={props.imgpath} alt="card-img" />
      <Card.Body>
        <Card.Title>{props.title}</Card.Title>
        <Card.Text style={{ textAlign: "center" }}>
          {props.description}
        </Card.Text>
        {props.ghlink && (
          <Button
            variant="primary"
            href={props.ghLink}
            target="_blank"
            style={{ marginLeft: "10px", marginTop: "10px" }}
          >
            <BsGithub />
            &nbsp;
            {props.isBlog ? "Blog" : "GitHub"}
          </Button>
        )}
        {"\n"}
        {"\n"}

        {props.isblog && (
          <Button
            onClick={() => {
              setShow(true);
            }}
            variant="primary"
            style={{ marginLeft: "10px", marginTop: "10px" }}
          >
            <CgWebsite /> &nbsp;
            {"ScreenShots"}
          </Button>
        )}
        <ImagesModal {...props} show={show} setShow={setShow}></ImagesModal>
        {props.demolink &&
          (props.info ? (
            <OverlayTrigger
              overlay={
                <Tooltip id="button-tooltip" {...props}>
                  {props.info}{" "}
                </Tooltip>
              }
            >
              <Button
                variant="primary"
                href={props.demolink}
                target="_blank"
                style={{ marginLeft: "10px", marginTop: "10px" }}
              >
                <CgWebsite /> &nbsp;
                {"Link"}
              </Button>
            </OverlayTrigger>
          ) : (
            <Button
              variant="primary"
              href={props.demolink}
              target="_blank"
              style={{ marginLeft: "10px", marginTop: "10px" }}
            >
              <CgWebsite /> &nbsp;
              {"Link"}
            </Button>
          ))}
        {props.telegramLink &&
          (props.info ? (
            <OverlayTrigger
              overlay={
                <Tooltip id="button-tooltip" {...props}>
                  {props.info}{" "}
                </Tooltip>
              }
            >
              <Button
                variant="primary"
                href={props.telegramLink}
                target="_blank"
                style={{ marginLeft: "10px", marginTop: "10px" }}
              >
                <BsTelegram /> &nbsp;
                {"Telegram"}
              </Button>
            </OverlayTrigger>
          ) : (
            <Button
              variant="primary"
              href={props.telegramLink}
              target="_blank"
              style={{ marginLeft: "10px", marginTop: "10px" }}
            >
              <BsTelegram /> &nbsp;
              {"Telegram"}
            </Button>
          ))}
        {props.chromeExtLink && (
          <Button
            variant="primary"
            href={props.chromeExtLink}
            target="_blank"
            style={{ marginLeft: "10px", marginTop: "10px" }}
          >
            <BsBrowserChrome /> &nbsp;
            {"Chrome Extension"}
          </Button>
        )}

        {props.appLink &&
          (props.appInfo ? (
            <OverlayTrigger
              overlay={
                <Tooltip id="button-tooltip" {...props}>
                  {props.appInfo}
                </Tooltip>
              }
            >
              <Button
                variant="primary"
                href={props.appLink}
                target="_blank"
                style={{ marginLeft: "10px", marginTop: "10px" }}
              >
                <FaAndroid /> &nbsp;
                {"Application"}
              </Button>
            </OverlayTrigger>
          ) : (
            <Button
              variant="primary"
              href={props.appLink}
              target="_blank"
              style={{ marginLeft: "10px", marginTop: "10px" }}
            >
              <FaAndroid /> &nbsp;
              {"Application"}
            </Button>
          ))}
      </Card.Body>
    </Card>
  );
};
export default ProjectCard;
