import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import LaptopImg from "../../assets/home-main.svg";
import Tilt from "react-parallax-tilt";

const About = () => {
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col md={8} className="home-about-description">
            <h1 style={{ fontSize: "2.6em" }}>
              LET ME <span className="yellow"> INTRODUCE </span> MYSELF
            </h1>
            <p className="home-about-body">
              Greetings, I am
              <span className="yellow"> Djedouani Nedjmeddine</span>, from
              <span className="yellow"> Algeria.</span>
              <br />
              <br />
              In 2015, I took up programming as a hobby. I moved into software
              development after earning a master's degree in electrical
              engineering in 2021, I switched my career to software development.
              This choice demonstrates my flexibility and openness to
              investigating fresh prospects in the rapidly developing realm of
              technology. I am a software developer that specializes in
              developing backend and mobile applications with an emphasis on
              performance, functionality, and user experience.
              <br />
       
              I enjoy tackling new challenges and continuously expanding my
              skillset.
              <br /> I am also interested in exploring areas related to
              <b className="yellow"> Artificial Intelligence.</b>
              <br />
            </p>
          </Col>
          <Col md={4} className="myAvtar">
            <Tilt>
              <img src={LaptopImg} className="img-fluid" alt="avatar" />
            </Tilt>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default About;
