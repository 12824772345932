import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "../components/Projects/ProjectCard";
import Particle from "../components/Particle";
import restaurant from "../assets/projects/restaurantdelivery.png";
import oferologo from "../assets/projects/ofero.svg";
import ecommerce from "../assets/projects/keep outfit 2.png";
import itaam from "../assets/projects/itaam.png";
import taskerapp from "../assets/projects/taskerapp.png";
import ostreoposis from "../assets/projects/OsteoporosisIcon.png";
import itaaamscreenshot1 from "../assets/projects/screenshots/itaam1.png";
import itaaamscreenshot2 from "../assets/projects/screenshots/itaam2.png";
import ecommercescreenshot from "../assets/projects/screenshots/ecommerce.png";
import restaurantscreenshot from "../assets/projects/screenshots/restaurant.png";
import taskerscreenshot1 from "../assets/projects/screenshots/tasker1.png";
import taskerscreenshot2 from "../assets/projects/screenshots/tasker2.png";
import taskerscreenshot3 from "../assets/projects/screenshots/tasker3.png";
import betaacademy from "../assets/projects/beta_acaademy_logo.jpeg";
import betaacademyscreenshot1 from "../assets/projects/screenshots/beta_academy.png";
import betaacademyscreenshot2 from "../assets/projects/screenshots/beta_academy2.png";
import betaacademyscreenshot3 from "../assets/projects/screenshots/beta_academy_3.png";
import ffsc from "../assets/projects/screenshots/framedfiction.png";
import ffsc2 from "../assets/projects/screenshots/framedfiction2.png";
import ffsc3 from "../assets/projects/screenshots/framedfiction3.png";
import ffsc4 from "../assets/projects/screenshots/framedfiction4.png";
import childrenclinic from "../assets/projects/children clinic.png";
import tadabbor from "../assets/projects/tadabbor.png";
import framedFiction from "../assets/projects/framed_fiction.png";
const Projects = () => {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          Recent <strong className="yellow">Works </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgpath={childrenclinic}
              isblog={false}
              title="sunshine pediatrics"
              description="clinic website for children , built with Laravel and livewire framework ,It serves as a digital front door for the clinic,
                 providing essential information to parents and guardians about the services offered and how to get in touch."
              demolink="https://sunshinepediatrics.vercel.app"
            ></ProjectCard>
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgpath={framedFiction}
              isblog={true}
              screenshotsurls={[ffsc, ffsc2, ffsc3, ffsc4]}
              title="Framed Fiction"
              description="FramedFiction is a platform built in NextJs and ExpressJs to connect comics and novels enthusiasts worldwide.
               It offers features like uploading releases, rating, commenting, search filter, and features for sharing and discovering new stories."
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgpath={tadabbor}
              isblog={false}
              title="Tadabbor"
              description="Tadabbor will tell you the quran verse of the day or a random quran verse with a random reciter, I used Nest js for the backend and integrate it with Chrome extension and Telegram bot for users"
              demolink="https://randomquranverse.xyz/api/ayah"
              telegramLink="https://t.me/RandomQuranVersesBot"
              chromeExtLink="https://chromewebstore.google.com/detail/tadabbor/cldffmckgpgdnfngkampjbbcjoebmioc"
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgpath={betaacademy}
              isblog={true}
              title="Beta Academy"
              description="Beta Academy is a Educational Courses Management System built in Laravel where Students can menage enrollements and their profile,Teachers menage their schedules/meetings  and subscribe to courses which are created by Admin, 
              It features also a notification system that sends meetings reminders to notify the students and the teachers by email"
              screenshotsurls={[
                betaacademyscreenshot1,
                betaacademyscreenshot2,
                betaacademyscreenshot3,
              ]}
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgpath={oferologo}
              isblog={false}
              title="Ofero"
              description="Ofero is a SaaS (Software as a service) for commercial buisnesses where the customers can menage their products to show them on smart screens  
              on real time i worked in the team as a backend developer my role was to refactor,fix bugs in the original codebase and add features like schedules, subscriptions,Themes and periodic backups for the database With NodeJs,ExpressJs and SocketIO "
              demolink="https://ofero.co"
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgpath={restaurant}
              isblog={true}
              info="user@user.com  admin@admin.com driver@driver.com  password:password"
              title="Turbo Meals Mobile App"
              description="The application features a user-friendly interface, allowing customers to easily place orders and track their progress
              . It also includes features for order management, allowing admins to accept or refuse orders, and a driver tracking their route using Google Maps and GPS.
              "
              screenshotsurls={[restaurantscreenshot]}
              appLink="https://play.google.com/store/apps/details?id=com.nejmdje.turbo_meals"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgpath={ecommerce}
              isblog={true}
              info="admin@admin.com password:password"
              appInfo="user@user.com  admin@admin.com  password:password"
              title="Keep Outfit Mobile App"
              description="Keep Outfit Mobile App is a flutter-powered platform that offers a user-friendly interface, product discovery,
               and streamlined checkout processes. It features a mongodb database, ReactJS dashboard, and a user-friendly interface with comprehensive product details.
                The app also allows customers to create profiles, manage preferences, and view order histories for personalized experiences.
              "
              screenshotsurls={[ecommercescreenshot]}
              appLink="https://www.mediafire.com/file/xtjbi7wh4jbz8ef/keep+outfit.apk/file"
              demolink="https://keepoutfit.vercel.app/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              screenshotsurls={[itaaamscreenshot1, itaaamscreenshot2]}
              imgpath={itaam}
              isblog={true}
              title="Itaam"
              description="The app is a user-friendly solution for addressing hunger and providing meals to those in need. It features a built-in Flutter and Firebase backend, 
              allowing easy navigation and access to features. Users can make meal donations, filter providers by location, and contact them via phone."
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgpath={taskerapp}
              isblog={true}
              title="Tasker App"
              description="this app is a part of two apps one for client and one for tasker. for the client part :the client search for specific
              service and appoint the tasker. for the tasker part :the tasker can menage his services ,working schedule...
              my role in the team was Flutter Developer , I integrated Rest Api from the backend Developer and converted the UI design to code"
              screenshotsurls={[
                taskerscreenshot1,
                taskerscreenshot2,
                taskerscreenshot3,
              ]}
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgpath={ostreoposis}
              isblog={false}
              title="ostreoposis detection script"
              description="script developed with paython and AI (machine learning and deep learning) for osteoporosis detection using different extraction methods and classfiers"
              ghlink="https://github.com/NedjmeDjedouani/osteoporosis-detection"
            />
          </Col>

          {/*         <Col md={4} className="project-card">
            <ProjectCard
              imgpath={kickstart}
              isblog={false}
              title="Solidity Kickstart"
              description="Solidity Kickstart is a Web 3 project that utilizes an Ethereum smart contract created using Solidity. It includes all the functionality of a kickstart platform, enabling users to interact with their Metamask wallet, transfer Ethereum and participate in kickstart campaigns."
              ghlink="https://github.com/19sajib/Solidity-Kickstart"
            />
          </Col> */}
        </Row>
        {/*   <h1 className="project-heading">Currently Working On</h1>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgpath={oferologo}
              isblog={false}
              title="Dashboard for Ecommerce App"
              description="Ofero is a SaaS (Software as a service) for commercial buisnesses where the customers can menage their products to show them on smart screens  
              on real time i worked in the team as a backend developer my role was to fix bugs and add some features like schedules, subscriptions and periodic backups for the database With NodeJs/ExpressJs "
              demolink="https://ofero.co"
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgpath={oferologo}
              isblog={false}
              title="MangaBeast"
              description="Ofero is a SaaS (Software as a service) for commercial buisnesses where the customers can menage their products to show them on smart screens  
              on real time i worked in the team as a backend developer my role was to fix bugs and add some features like schedules, subscriptions and periodic backups for the database With NodeJs/ExpressJs "
              demolink="https://ofero.co"
            />
          </Col>
        </Row> */}
      </Container>
    </Container>
  );
};

export default Projects;
